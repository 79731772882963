<template>
    <QuestionTemplate
        :question="question"
        :description="description"
        :isRequired="isRequired"
    >
        <ValidationProvider
            :name="question"
            :rules="validateRules"
            :customMessages="{ required: customMessages }"
            v-slot="{ errors }"
        >
            <v-checkbox
                v-for="(item, index) in computedItems"
                :key="index"
                :label="item"
                :value="item"
                :error="errors.length"
                :disabled="!fieldEditable"
                v-model="selectedValue"
            />

            <span v-if="errors.length" class="red--text">{{ errors[0] }}</span>
        </ValidationProvider>
    </QuestionTemplate>
</template>

<script>
import QuestionTemplate from './QuestionTemplate.vue'
import { ValidationProvider } from 'vee-validate'
import i18n from '@/i18n'

export default {
    props: {
        question: String,
        description: String,
        fieldEditable: Boolean,
        isEditable: Boolean,
        isRequired: Boolean,
        options: Array,
        existingValue: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ValidationProvider,
        QuestionTemplate,
    },
    data() {
        return {
            selectedValue: this.parsedExistingValue,
            customMessages: i18n.t('message.thisFieldNoEmpty'),
        }
    },
    created() {
        if (this.fieldEditable) {
            const optionValues = this.options.map((option) => option.value)
            const existingValuesInOption = this.existingValue.filter((value) =>
                optionValues.includes(value)
            )

            this.selectedValue = existingValuesInOption
        } else {
            this.selectedValue = this.existingValue
        }
    },
    watch: {
        selectedValue: {
            handler() {
                this.emitValue()
            },
        },
    },
    computed: {
        computedItems() {
            return this.options.map((item) => item.value)
        },
        validateRules() {
            return this.isRequired ? 'required' : ''
        },
        hint() {
            return !this.fieldEditable && !this.isEditable
                ? i18n.t(`message.fieldCannotBeChanged`)
                : ''
        },
        isRequiredFieldEmpty() {
            const isArrayEmpty = this.existingValue.length === 0
            return this.fieldEditable && isArrayEmpty && this.isRequired
        },
        isPersistentHint() {
            return !this.fieldEditable && !this.isEditable
        },
        parsedExistingValue() {
            if (this.fieldEditable) {
                const optionValues = this.options.map((option) => option.value)

                const existingValues = this.existingValue.filter((value) =>
                    optionValues.includes(value)
                )

                return existingValues
            }

            return this.existingValue
        },
    },
    methods: {
        emitValue() {
            this.$emit('onValueChanged', this.selectedValue)
        },
    },
}
</script>

<style scoped>
.question-text {
    white-space: normal;
    word-break: break-word;
}
</style>
