import { auth, signOut } from '@/firebase'
import getUserDocument from '@/util/cloudFunctions/userFunctions'

const DEFAULT_ERROR_MESSAGE =
    'Something went wrong. Please contact vibefam if the problem persists.'

export const actionsAuth = {
    authAction({ commit, state }) {
        auth.onIdTokenChanged(async (user) => {
            commit('setLoading', true)
            if (user) {
                const isRegistering = state.isRegistering
                try {
                    await setUserDocument(
                        { commit, state },
                        user,
                        isRegistering
                    )
                } catch (error) {
                    commit('setError', DEFAULT_ERROR_MESSAGE)
                    commit('setLoading', false)
                }
            } else {
                commit('setUser', null)
                commit('setFirebaseUser', null)
            }
            commit('setLoading', false)
        })
    },
    setBrandedApp({ commit }) {
        commit('setBrandedApp', true)
    },
    setIsRegistering({ commit }, isRegistering) {
        commit('setIsRegistering', isRegistering)
    },
    closeCollectUserDetails({ commit }) {
        commit('setCollectUserDetails', false)
    },
    async refreshUserDocument() {
        await auth.currentUser.reload()
    },
}

async function setUserDocument(
    { commit, state },
    user,
    isRegistering,
    tries = 0
) {
    if (state.isFetchingUser) return

    if (state.lastSuccessFetch) {
        const currentTime = new Date()
        const diff = currentTime - state.lastSuccessFetch
        const diffSeconds = diff / 1000
        if (diffSeconds < 10) {
            return
        }
    }

    commit('setError', null)
    commit('setIsFetchingUser', true)
    const result = await getUserDocument(user.uid || user.id)
    try {
        if (!result.success && tries < 3) {
            // wait 0.5 seconds and retry 3 times
            // doing this because sometimes the user document is not created immediately after the user is created
            await new Promise((resolve) => setTimeout(resolve, 500))
            return setUserDocument(commit, user, isRegistering, tries + 1)
        }

        if (result.success) {
            const userDoc = result.doc
            commit('setUser', userDoc)
            commit('setFirebaseUser', user)
            commit('setLastSuccessFetch', new Date())
        } else {
            // Logged in but cannot find user document, so we need to collect the user details
            // Only happens with external auth
            if (auth.currentUser.providerData[0].providerId !== 'password') {
                commit('setUser', { id: auth.currentUser.uid })
                commit('setCollectUserDetails', true)

                return
            }

            // Handle errors during login
            // This might occur due to an unknown bug in the signup process.
            // In the signup process, the user may have been created in Firebase Auth,
            // but the user document in the Firestore collection was not created.
            // As a result, the login process should fail and display an error message.
            commit('setError', result.error || DEFAULT_ERROR_MESSAGE)

            /**
             * If the user is not registering, sign them out. This case is to handle the bug when user is logged in but the user document is not found.
             * And if the user is registering, don't sign them out as the user will be deleted from Firebase Auth.
             */
            if (!isRegistering) {
                await signOut(auth)
            }
        }
    } finally {
        commit('setIsFetchingUser', false)
    }
}
