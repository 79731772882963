export const mutationsAuth = {
    setError(state, payload) {
        state.error = payload
    },
    setLoading(state, payload) {
        state.loading = payload
    },
    setIsRegistering(state, payload) {
        state.isRegistering = payload
    },
    setBrandedApp(state, payload) {
        state.isBrandedApp = payload
    },
    setUser(state, payload) {
        state.user = payload
    },
    setCollectUserDetails(state, payload) {
        state.collectUserDetails = payload
    },
    setFirebaseUser(state, payload) {
        state.firebaseUser = payload
    },
    setIsFetchingUser(state, payload) {
        state.isFetchingUSer = payload
    },
    setLastSuccessFetch(state, payload) {
        state.lastSuccessFetch = payload
    },
}
