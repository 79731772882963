<template>
    <div v-if="!isEmailVerified && isUserAuth">
        <v-snackbar
            v-model="snackbar.show"
            :color="snackbar.type"
            timeout="2000"
            top
        >
            <div class="white--text">
                {{ snackbar.text }}
            </div>
        </v-snackbar>
        <v-alert
            color="amber lighten-2"
            type="warning"
            text
            border="left"
            :icon="false"
        >
            <div class="text-h6 text--primary font-weight-bold">
                {{ $t('message.verifyEmailAlert.title') }}
            </div>
            <div class="caption text--primary">
                {{ $t('message.verifyEmailAlert.content') }}
            </div>
            <a class="overline" @click="resendVerificationEmail()">
                {{ $t('message.verifyEmailAlert.button') }}
            </a></v-alert
        >
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { AUTHENTICATION_MODULE_NAME } from '@/store/moduleNames'
import { auth, sendEmailVerification } from '@/firebase'
import getAuthErrorMessage from '@/util/authErrors'
export default {
    data() {
        return {
            message: 'Focus on the window to see changes.',
            snackbar: {
                show: false,
                text: '',
                type: 'success',
            },
            isSendingVerificationEmail: false,
        }
    },
    methods: {
        ...mapActions({
            refreshUserDocument: `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`,
        }),
        async handleWindowFocus() {
            if (this.isEmailVerified || !this.isUserAuth) {
                return
            }

            await this.refreshUserDocument()
        },
        async resendVerificationEmail() {
            if (this.isSendingVerificationEmail) return

            try {
                this.isSendingVerificationEmail = true
                await sendEmailVerification(auth.currentUser)
                this.showSuccessToast()
            } catch (error) {
                this.showErrorToast(getAuthErrorMessage(error.code))
            } finally {
                this.isSendingVerificationEmail = false
            }
        },
        showSuccessToast() {
            this.snackbar.text = this.$t('message.verifyEmailAlert.success')
            this.snackbar.type = 'success'
            this.snackbar.show = true
        },
        showErrorToast(errorMsg) {
            this.snackbar.text = `${this.$t(
                'message.verifyEmailAlert.failed'
            )}: ${errorMsg}`
            this.snackbar.type = 'error'
            this.snackbar.show = true
        },
    },
    computed: {
        ...mapGetters({
            isEmailVerified: `${AUTHENTICATION_MODULE_NAME}/isEmailVerified`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        }),
    },
    mounted() {
        window.addEventListener('focus', this.handleWindowFocus)
    },
    beforeUnmount() {
        // use `beforeDestroy` in Vue 2
        window.removeEventListener('focus', this.handleWindowFocus)
    },
}
</script>
