<template>
    <v-container class="py-10 px-0 px-sm-5">
        <PurchaseStatus />
        <BackButton />
        <ProgressLoader v-if="loading" />
        <div v-if="!loading && !classExists" class="class-error">
            <v-img
                :src="require('@/assets/search-error.gif')"
                alt="Class does not exist"
                max-width="400px"
                min-height="400px"
            />
            <div class="class-error-text">
                {{ $t('message.classDoesNotExist') }}
            </div>
        </div>
        <v-col v-if="!loading && classExists">
            <v-row class="top-border">
                <ClassIntroduction :classObject="this.classItem" />
            </v-row>
            <v-row>
                <v-col cols="12" md="7" v-if="!hideSpots" class="px-0">
                    <ChooseSpot
                        :spots="classItem.spots"
                        :height="classItem.height"
                        :width="classItem.width"
                        @input="updateSpotNumbers"
                        :key="chooseSpotKey"
                        :maxBookings="maxBookings"
                    />
                </v-col>
                <v-col>
                    <v-col cols="12">
                        <h2 class="book-spot-header">
                            {{ hideSpots ? '' : '2.' }}
                            {{ $t('message.choosePackage') }}
                        </h2>
                        <v-chip
                            class="mt-3 mr-3"
                            v-if="hideSpots && maxBookings > 1"
                        >
                            {{
                                $t('message.bookMaximumSpots', {
                                    maxBookings: maxBookings,
                                })
                            }}
                        </v-chip>
                        <v-chip
                            class="mt-3 mr-3"
                            color="primary"
                            v-if="numSpotsBooked > 0"
                        >
                            {{
                                $t('message.bookedNumSpots', {
                                    numSpotsBooked: numSpotsBooked,
                                })
                            }}
                        </v-chip>
                        <v-chip
                            class="mt-3 mr-3"
                            color="secondary darken-3"
                            v-if="waitlistPosition !== null"
                        >
                            {{
                                $t('message.waitlistPosition', {
                                    waitlistPosition: this.waitlistPosition,
                                })
                            }}
                        </v-chip>
                    </v-col>
                    <BookSpot
                        :classObject="classItem"
                        :isBookable="isBookable"
                        :reason="reason"
                        :normalMemberships="normalMemberships"
                        :recurringMemberships="recurringMemberships"
                        :myMemberships="myMemberships"
                        :maxBookings="maxBookings"
                        :isWaitlistClass="isWaitlistClass"
                        :loading="loadButton"
                        :isClassCancellable="isClassCancellable"
                        @input="updateSelectedPackage"
                        @onNumSpots="updateNumSpots"
                        @click="submit"
                        @reload="reload"
                        :key="bookSpotKey"
                    />
                    <CancelSpotOrWaitlist
                        :hideSpots="hideSpots"
                        :loadButton="loadButton"
                        :userBookedSpots="userBookedSpots"
                        :userWaitlist="userWaitlist"
                        :isClassCancellable="isClassCancellable"
                        :isWaitlistCancellable="isWaitlistCancellable"
                        @success="reload"
                        @cancelFeePaymentError="handleError"
                    />
                    <div class="error-text">{{ validationError }}</div>
                </v-col>
            </v-row>
            <CheckoutWindow
                :hideSpots="hideSpots"
                ref="CheckoutWindow"
                :packageItem="selectedPackage ? selectedPackage : {}"
                :packageId="selectedPackage ? selectedPackage.id : ''"
                :classItem="classItem"
                :spotNumbers="spotNumbers"
                :numSpots="numSpots"
                :isPurchasePackage="isPurchasePackage"
                :isBookClass="!isPurchasePackage"
                :isWaitlistClass="isWaitlistClass"
                :hideButton="true"
                :key="checkoutWindowKey"
                @closeDialog="reload"
                @error="handleError"
            />
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import {
    AUTHENTICATION_MODULE_NAME,
    STUDIO_MODULE_NAME,
} from '@/store/moduleNames'

import ClassIntroduction from '@/components/bookclass/ClassIntroduction.vue'
import BookSpot from '@/components/bookclass/BookSpot.vue'
import CancelSpotOrWaitlist from '@/components/cancelclass/CancelSpotOrWaitlist'
import ChooseSpot from '@/components/bookclass/ChooseSpot.vue'
import ProgressLoader from '@/components/shared/ProgressLoader'
import CheckoutWindow from '@/components/checkout/CheckoutWindow.vue'
import PurchaseStatus from '@/components/stripe/PurchaseStatus'
import BackButton from '@/components/shared/BackButton.vue'

import { getClassByClassId } from '@/util/cloudFunctions/classesFunctions'
import {
    getNormalPackages,
    getRecurringPackages,
    getMyMemberships,
} from '@/util/cloudFunctions/packagesFunctions'
import { isPackageValidForClass } from '@/util/packages/checkPackagesValidity'
// Cancel bookings or waitlist
import {
    getUserUpcomingClasses,
    getUserWaitlist,
} from '@/util/cloudFunctions/classesFunctions'

export default {
    components: {
        ClassIntroduction,
        BookSpot,
        CancelSpotOrWaitlist,
        ProgressLoader,
        ChooseSpot,
        CheckoutWindow,
        PurchaseStatus,
        BackButton,
    },
    data() {
        return {
            classItem: {},
            error: '',
            isBookable: false,
            reason: '',
            loading: true, // loading page
            loadingClass: false,
            numSpots: 1,
            spotNumbers: [],
            selectedPackage: null,
            isPurchasePackage: false,
            validationError: '',
            chooseSpotKey: false,
            bookSpotKey: false,
            checkoutWindowKey: false,
            normalMemberships: [],
            recurringMemberships: [],
            myMemberships: [],
            classExists: false,
            waitlistPosition: null,
            userBookedSpots: [],
            userWaitlist: null,
            loadButton: true,
            loadingUserBookedSpots: true,
            loadingUserWaitlist: true,
        }
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to !== from) {
                    this.setRequiredDataWithLoading()
                }
            },
            immediate: true,
        },
        isUserAuth: {
            handler() {
                this.setRequiredData()
            },
        },
    },
    computed: {
        hideSpots() {
            return this.classItem.hideSpots === true
        },
        ...mapGetters({
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        maxBookings() {
            if (!this.classItem.isGuestBookable) {
                return 1
            }
            return (this.classItem.maxGuestBookings ?? 0) + 1
        },
        numSpotsBooked() {
            if (!this.user) {
                return 0
            }
            return this.classItem.spots.filter(
                (spot) => spot.username === this.user.username
            ).length
        },
        isClassFull() {
            const freeSpot = this.classItem.spots.find((spot) => {
                return !spot.isOccupied && !spot.isInstructor && !spot.isBlocked
            })
            return !freeSpot
        },
        isWaitlistClass() {
            return !this.isBookable && this.isClassFull
        },
        isClassCancellable() {
            return this.isUserAuth && this.userBookedSpots?.length > 0
        },
        isWaitlistCancellable() {
            return this.isUserAuth && !!this.userWaitlist
        },
    },
    methods: {
        async setRequiredDataWithLoading() {
            this.loading = true
            await this.setRequiredData()
            this.loading = false
        },
        async setRequiredData() {
            await this.setClasses() // this function needs to run before the other functions, so that
            if (!this.classExists) return
            const promises = [
                this.setNormalPackages(),
                this.setRecurringPackages(),
                this.setUserPackages(),
                this.fetchUserBookedSpotsAndWaitlist(),
            ]

            await Promise.all(promises)
        },
        async setClasses() {
            const classId = this.$route.params.classId
            this.loadButton = true // start loading button, stop loading after fetching booked spots / waitlist
            const result = await getClassByClassId({ classId })
            if (result.success) {
                this.classItem = result.doc
                // Create an array of object here to make it scalable in the future
                // and to avoid any side effects.
                this.classItem.urls = this.classItem.images.map((image) => {
                    return { type: 'image', url: image }
                })

                if (this.classItem.videoUrl) {
                    this.classItem.urls.unshift({
                        type: 'video',
                        url: this.classItem.videoUrl,
                    })
                }
                this.isBookable = result.isBookable
                this.reason = result.reason
                this.classExists = true
            } else {
                this.error = result.error
                this.classExists = false
            }
        },
        filterUsablePackages(packages) {
            // This function helps to filter out packages which cannot be used to book this class
            return packages.filter((pkg) =>
                isPackageValidForClass(
                    pkg,
                    this.classItem,
                    this.studio.timezone
                )
            )
        },
        async setNormalPackages() {
            const result = await getNormalPackages(this.studio.id)
            if (result.success) {
                this.normalMemberships = this.filterUsablePackages(
                    result.memberships
                )
            }

            if (result.error) {
                this.error = result.error
            }
        },
        async setRecurringPackages() {
            const result = await getRecurringPackages(this.studio.id)
            if (result.success) {
                this.recurringMemberships = this.filterUsablePackages(
                    result.memberships
                )
            }

            if (result.error) {
                this.error = result.error
            }
        },
        async setUserPackages() {
            const result = await getMyMemberships(this.studio.id)
            if (result.success) {
                this.myMemberships = this.filterUsablePackages(
                    result.docs
                ).filter((doc) => doc.classesLeft > 0)
            }

            if (result.error) {
                this.error = result.error
            }
        },
        updateSpotNumbers(spotNumbers) {
            this.spotNumbers = spotNumbers
        },
        updateSelectedPackage(selectedPackage, isMyPackage) {
            this.selectedPackage = selectedPackage
            this.isPurchasePackage = !isMyPackage
        },
        updateNumSpots(numSpots) {
            this.numSpots = numSpots
        },
        validate(isWaitlist) {
            if (!this.spotNumbers.length && !isWaitlist && !this.hideSpots) {
                this.validationError = this.$t(
                    'message.classSelectSpotBeforeBooking'
                )
                return false
            }

            if (!this.selectedPackage) {
                this.validationError = this.$t(
                    'message.classSelectPackageBeforeBooking'
                )
                return false
            }

            if (this.selectedPackage.classesLeft <= 0) {
                this.validationError = this.$t(
                    'message.classSelectValidPackage'
                )
                return false
            }

            if (this.hideSpots) {
                if (this.selectedPackage.classesLeft < this.numSpots) {
                    this.validationError = this.$t(
                        'message.classSelectValidPackageEnoughClasses'
                    )
                    return false
                }
            } else {
                if (
                    this.selectedPackage.classesLeft < this.spotNumbers.length
                ) {
                    this.validationError = this.$t(
                        'message.classSelectValidPackageEnoughClasses'
                    )
                    return false
                }
            }

            const spotsValid = this.spotNumbers || this.hideSpots

            if (spotsValid && this.selectedPackage && !isWaitlist) {
                this.validationError = ''
                return true
            }

            if (this.selectedPackage && isWaitlist) {
                this.validationError = ''
                return true
            }
            return false
        },
        submit(isWaitlist) {
            const validation = this.validate(isWaitlist)
            if (validation) {
                this.$refs.CheckoutWindow.openDialog()
            }
        },
        async reload() {
            await this.setClasses()
            await this.setUserPackages()
            this.toggleKeys()
            await this.fetchUserBookedSpotsAndWaitlist()
        },
        toggleKeys() {
            this.chooseSpotKey = !this.chooseSpotKey
            this.bookSpotKey = !this.bookSpotKey
            this.checkoutWindowKey = !this.checkoutWindowKey
        },
        handleError(error) {
            this.validationError = error
        },
        async fetchUserBookedSpots() {
            this.loadButton = true
            this.loadingUserBookedSpots = true
            const doc = await getUserUpcomingClasses(this.studio.id)
            if (doc.success) {
                const result = doc.docs.filter(
                    (bookedClassItem) =>
                        bookedClassItem.classId === this.classItem.id
                )
                this.userBookedSpots = result
            }
            this.loadingUserBookedSpots = false
            this.loadButton = false
        },
        async fetchUserWaitlist() {
            this.loadButton = true
            this.loadingUserWaitlist = true
            const doc = await getUserWaitlist(this.studio.id)
            if (doc.success) {
                const result = doc.docs.filter(
                    (waitlistItem) => waitlistItem.classId === this.classItem.id
                )
                if (result.length > 0) {
                    this.userWaitlist = result[0] // should have only one waitlist for a class
                    this.waitlistPosition = this.userWaitlist.waitlistPosition
                }
            }
            this.loadingUserWaitlist = false
            this.loadButton = false
        },
        async fetchUserBookedSpotsAndWaitlist() {
            // reset userBookedSpots and userWaitlist
            this.userBookedSpots = []
            this.userWaitlist = null
            this.waitlistPosition = null
            Promise.all([this.fetchUserBookedSpots(), this.fetchUserWaitlist()])
        },
    },
}
</script>

<style scoped>
.top-border {
    border-bottom: 0.2px solid #000;
}

.book-spot-header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /* font-size: 45px; */
    line-height: 32px;
    /* identical to box height, or 71% */

    display: flex;
    align-items: center;
}

.error-text {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    color: red;
}

.class-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.class-error-text {
    font-family: Roboto;
    font-size: 24px;
    text-align: center;
}
</style>
