export const gettersAuth = {
    isLoading(state) {
        return state.loading
    },
    isRegistering(state) {
        return state.isRegistering
    },
    getUser(state) {
        return state.user
    },
    isBrandedApp(state) {
        return state.isBrandedApp
    },
    getError(state) {
        return state.error
    },
    isCollectUserDetails(state) {
        return state.collectUserDetails
    },
    isUserAuth(state) {
        return !!state.user
    },
    isUserMobileNumberRegistered(state) {
        if (!state.user) {
            return false
        }
        const user = state.user
        const personalDetails = user.personalDetails
        if (!personalDetails) {
            return false
        }
        const mobileNumber = personalDetails.mobileNum
        return !!mobileNumber
    },
    isEmailVerified(state) {
        return state.firebaseUser?.emailVerified
    },
    isFetchingUser(state) {
        return state.isFetchingUser
    },
    getLastSuccessFetch(state) {
        return state.lastSuccessFetch
    },
}
