<template>
    <div>
        <PaymentMethods
            packageId
            :isCharging="true"
            :statusObjProp="statusObj"
            :buttonProps="buttonProps"
            @onButtonClicked="openDialog"
        />

        <WarningDialog
            :showDialog="dialog"
            :title="title"
            :content="$t('message.chargePackageCancellationMessage')"
            :confirmButtonText="$t('message.confirm')"
            :confirmCallback="chargePackageCancellation"
            :cancelCallback="closeDialog"
            :errorMessage="error"
            :submitting="statusObj.loading"
        >
            <TaxPriceBreakdown
                v-if="showTaxBreakdown"
                :isStatic="true"
                :inputPrice="subscriptionCancellationFee"
                :totalPrice="totalFee"
                :taxBreakdown="taxes.taxBreakdown"
            />
        </WarningDialog>
    </div>
</template>

<script>
import PaymentMethods from '@/views/PaymentMethods.vue'
import WarningDialog from '@/components/shared/WarningDialog.vue'
import { cancelRecurringPackage } from '@/util/cloudFunctions/packagesFunctions'
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import TaxPriceBreakdown from '../tax/TaxPriceBreakdown.vue'

export default {
    components: {
        PaymentMethods,
        WarningDialog,
        TaxPriceBreakdown,
    },
    props: {
        subscriptionCancellationFee: {
            type: Number,
            required: false,
            default: null,
        },
        taxes: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    inject: ['packageId'],
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        totalFee() {
            if (this.taxes.taxBreakdown && this.taxes.taxBreakdown.length > 0) {
                return this.taxes.finalPrice
            }
            return this.subscriptionCancellationFee
        },
        title() {
            return this.$t('message.chargePackageCancellationTitle', {
                currency: this.subscriptionCancellationFee
                    ? this.studio.currency ?? '$'
                    : '',
                fee: this.subscriptionCancellationFee ?? '',
            })
        },
        showTaxBreakdown() {
            return this.taxes.taxBreakdown && this.taxes.taxBreakdown.length > 0
        },
    },

    data() {
        return {
            statusObj: {
                loading: false,
                paymentMethodId: null,
            },
            buttonProps: {
                show: true,
                outlined: true,
                small: true,
                color: 'primary',
                text: this.$t('message.chargePayment'),
            },
            dialog: false,
            isLoading: false,
            error: null,
            paymentMethodIdSelected: null,
        }
    },
    methods: {
        openDialog(paymentMethodId) {
            this.paymentMethodIdSelected = paymentMethodId
            this.dialog = true
        },
        closeDialog() {
            this.paymentMethodIdSelected = null
            this.dialog = false
        },
        handleEmitStatus(isSuccess, error) {
            this.$emit('chargeStatus', isSuccess, error)
        },
        async chargePackageCancellation() {
            this.statusObj.loading = true
            this.statusObj.paymentMethodId = this.paymentMethodIdSelected
            this.$emit('chargeLoading', true)

            const result = await cancelRecurringPackage(
                this.packageId,
                this.paymentMethodIdSelected
            )

            if (result.success) {
                let errorMessage = null

                if (result.error) {
                    errorMessage = `${this.$t(
                        'message.chargePackageCancellationError'
                    )}<br>Error: ${result.error}`
                }

                this.handleEmitStatus(true, errorMessage)
                this.closeDialog()
            } else {
                this.error = result.error
                this.handleEmitStatus(false, this.error)
            }

            this.$emit('chargeLoading', false)
            this.statusObj.loading = false
            this.statusObj.paymentMethodId = null
        },
    },
}
</script>
